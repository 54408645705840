import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
  connect() {
    super.connect()
    // console.log("Hello Select All Controller")
    // console.log(this.checkboxAllTarget)
    // console.log(this.checked)
    // console.log(this.unchecked)
  }
}
