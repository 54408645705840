'use strict';

import 'bootstrap-datepicker'

var initDatePickers = (function() {
	var $datePicker = $('.datepicker');

	function init($this) {
		var options = {
			disableTouchKeyboard: true,
			autoclose: false
		};

		$this.datepicker(options);
	}

	if ($datePicker.length) {
		$datePicker.each(function() {
			init($(this));
		});
	}
});

document.addEventListener('turbo:load', initDatePickers);
