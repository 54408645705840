'use strict';

import Muuri from 'muuri'

var initSquadRosterEditor = (function() {
  var itemContainers = [].slice.call(document.querySelectorAll('.board-column-content'));
  var columnGrids = [];

  // Define the column grids so we can drag items around between squads and unassigned
  itemContainers.forEach(function (container) {
    // Instantiate column grid.
    var grid = new Muuri(container, {
      items: '.board-item',
      layoutDuration: 200,
      layoutEasing: 'ease',
      dragEnabled: true,
      dragSort: function () {
        return columnGrids;
      },
      dragSortInterval: 0,
      dragContainer: document.body,
      dragReleaseDuration: 200,
      dragReleaseEasing: 'ease'
    }).on('dragStart', function (item) {
      // Let's set fixed widht/height to the dragged item
      // so that it does not stretch unwillingly when
      // it's appended to the document body for the
      // duration of the drag.
      item.getElement().style.width = item.getWidth() + 'px';
      item.getElement().style.height = item.getHeight() + 'px';
    }).on('dragReleaseEnd', function (item) {
      // Let's remove the fixed width/height from the
      // dragged item now that it is back in a grid
      // column and can freely adjust to it's
      // surroundings.
      item.getElement().style.width = '';
      item.getElement().style.height = '';
      // Just in case, let's refresh the dimensions of all items
      // in case dragging the item caused some other items to
      // be different size.
      columnGrids.forEach(function (grid) {
        grid.refreshItems();
      });
    })

    // Add the column grid reference to the column grids
    // array, so we can access it later on.
    columnGrids.push(grid);
  });

  // This gets called when the form is submitted. We use it to
  // set the squad ids for each of the members
  function updateSquadMembers(columnGrids) {
    columnGrids.forEach(function(grid) {
      console.log("processing grid " + grid)
      var columnContent = grid.getElement();
      var column = columnContent.closest('.board-column');

      var squadId = "";
      if (column.getAttribute('class').includes('squad')) {
        squadId = column.getAttribute('data-squad-id')
        console.log("squadId = " + squadId);
      }

      var items = grid.getItems();
      items.forEach(function(item) {
        var el = item.getElement();
        console.log("member.id = " + el.getAttribute('data-member-id'));
        var squad_member_hidden_field = el.querySelector('.squad_member_field');
        squad_member_hidden_field.value = squadId;
      })
    })
  }

  var form = document.querySelector('#squad-roster-form');
  if (form) {
    console.log("Adding form submit event handler");
    form.addEventListener('submit', function(e) {
      e.preventDefault();
      updateSquadMembers(columnGrids);
      form.submit();
    });
  }
});

document.addEventListener('turbo:load', initSquadRosterEditor);
