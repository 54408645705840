//
// Navbar
//

'use strict';

// Expand the nav menu with the active link
var initSideNav = (function() {
	var $activeItem = $('.sidenav .active');
	var $c = $activeItem.closest('.collapse');
	$c.collapse('show');
});

var initNavbar = (function() {
	// Variables
	var $nav = $('.navbar-nav, .navbar-nav .nav');
	var $collapse = $('.navbar .collapse');
	var $dropdown = $('.navbar .dropdown');

	// Methods
	function accordion($this) {
		$this.closest($nav).find($collapse).not($this).collapse('hide');
	}

	function closeDropdown($this) {
		var $dropdownMenu = $this.find('.dropdown-menu');
		$dropdownMenu.addClass('close');

		setTimeout(function() {
			$dropdownMenu.removeClass('close');
		}, 200);
	}

	// Events
	$collapse.on({
		'show.bs.collapse': function() {
			accordion($(this));
		}
	})

	$dropdown.on({
		'hide.bs.dropdown': function() {
			closeDropdown($(this));
		}
	})

});


//
// Navbar collapse
//
var navbarCollapse = (function() {

	// Variables
	var $nav = $('.navbar-nav'),
	$collapse = $('.navbar .navbar-custom-collapse');


	// Methods
	function hideNavbarCollapse($this) {
		$this.addClass('collapsing-out');
	}

	function hiddenNavbarCollapse($this) {
		$this.removeClass('collapsing-out');
	}


	// Events
	if ($collapse.length) {
		$collapse.on({
			'hide.bs.collapse': function() {
				hideNavbarCollapse($collapse);
			}
		})

		$collapse.on({
			'hidden.bs.collapse': function() {
				hiddenNavbarCollapse($collapse);
			}
		})
	}

});

document.addEventListener("turbo:load", initSideNav)
document.addEventListener("turbo:load", initNavbar)
document.addEventListener("turbo:load", navbarCollapse)
