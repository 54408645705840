'use strict';

var initRemoveCoachButtons = (function() {
  function removeCoach(event) {
    event = event || window.event;
    var target = event.target || event.srcElement;

    // Hide the div containing the organisation
    var coachElement = $(target).closest('div.coach');
    $(coachElement).hide();

    // And mark the squad-coach relationship to be removed
    $(coachElement).find("input[type='hidden']").attr("value", "1");
  }

  var removeCoachButton = $('[data-action="remove-coach"]');
  $(removeCoachButton).on('click', removeCoach);

  // Watch for new remove coach buttons being added and add the above
  // event handler to those too
  function addRemoveCoachHandler(event) {
    var removeCoachButton = $(event.target).find('[data-action="remove-coach"]')
    if ($(removeCoachButton).length > 0) {
      $(removeCoachButton).on('click', removeCoach);
    }
  }

  var coachesElement = $('#coaches')
  $(coachesElement).on('DOMNodeInserted', addRemoveCoachHandler);
});

document.addEventListener('turbo:load', initRemoveCoachButtons);
