import { Controller } from 'stimulus'
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "url", "message" ]

  doStripeCustomerPortalSession() {
    Rails.ajax({
      url: this.urlTarget.value,
      type: "post",
      dataType: 'script',
      data: "",
      success: this.redirectToStripeCustomerPortal.bind(this),
      error: this.showError.bind(this)
    })
  }

  redirectToStripeCustomerPortal(data) {
    return window.location.href = data.url
  }

  showError(data) {
    this.messageTarget.innerHTML = `Error: ${data.error.message}. Please contact us for help.`
  }
}
