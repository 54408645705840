import { Controller } from 'stimulus'
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "url", "message" ]

  connect() {
    const stripeKey = document.querySelector("meta[name='stripe-key']").getAttribute("content")
    const stripeAccount = document.querySelector("meta[name='stripe-account']").getAttribute("content")
    this.stripe = Stripe(stripeKey, { stripeAccount: stripeAccount })
  }

  doStripeCheckoutSession() {
    Rails.ajax({
      url: this.urlTarget.value,
      type: "post",
      dataType: 'script',
      data: "",
      success: this.redirectToStripeCheckout.bind(this),
      error: this.showError.bind(this)
    })
  }

  redirectToStripeCheckout(data) {
    return this.stripe.redirectToCheckout({ sessionId: data.sessionId });
  }

  showError(data) {
    this.messageTarget.innerHTML = `Error: ${data.error.message}. Please contact us for help.`
  }
}
