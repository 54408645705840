'use strict'

import Croppie from 'croppie';
import 'croppie/croppie.css'

class CropperController {
  constructor(element) {
    this.element = element;
    this.containerElement = this.element.querySelector("[data-cropper='container']");
    this.uploadElement = this.element.querySelector("[data-cropper='upload']");
    this.rotateCcwElement = this.element.querySelector("[data-cropper='rotate-ccw']");
    this.rotateCwElement = this.element.querySelector("[data-cropper='rotate-cw']");
    this.dataElement = this.element.querySelector("[data-cropper='data']");
    this.formElement = this.element.closest("form");

    var options = {
      viewport: {
        width: 200,
        height: 200,
        type: 'circle'
      },
      boundary: { width: 220, height: 220 },
      enableOrientation: true,
      enableExif: true
    };

    // Set options from element data attributes if present
    if (this.element.hasAttribute('data-cropper-viewport-width')) {
      options.viewport.width = parseInt(this.element.getAttribute('data-cropper-viewport-width'));
    }

    if (this.element.hasAttribute('data-cropper-viewport-height')) {
      options.viewport.height = parseInt(this.element.getAttribute('data-cropper-viewport-height'));
    }

    if (this.element.hasAttribute('data-cropper-viewport-shape')) {
      options.viewport.type = this.element.getAttribute('data-cropper-viewport-shape');
    }

    if (this.element.hasAttribute('data-cropper-boundary-width')) {
      options.boundary.width = parseInt(this.element.getAttribute('data-cropper-boundary-width'));
    }

    if (this.element.hasAttribute('data-cropper-boundary-height')) {
      options.boundary.height = parseInt(this.element.getAttribute('data-cropper-boundary-height'));
    }

    this.croppie = new Croppie(this.containerElement, options);

    this.installEventHandlers();
  }

  installEventHandlers() {
    this.uploadElement.addEventListener("change", this.readFile.bind(this));
    this.rotateCcwElement.addEventListener("click", this.rotateImageCcw.bind(this));
    this.rotateCwElement.addEventListener("click", this.rotateImageCw.bind(this));
    this.formElement.addEventListener("submit", this.extractImage.bind(this));
  }

  readFile() {
    if (this.uploadElement.files && this.uploadElement.files[0]) {
      var reader = new FileReader();
      reader.addEventListener("load", this.setImage.bind(this));
      reader.readAsDataURL(this.uploadElement.files[0]);
    }
  }

  setImage(event) {
    this.croppie.bind({ url: event.target.result });
  }

  rotateImage(degrees) {
    this.croppie.rotate(degrees);
  }

  rotateImageCcw() {
    this.rotateImage(90);
  }

  rotateImageCw() {
    this.rotateImage(-90);
  }

  extractImage(event) {
    // If a file is set then convert it to base64 data and store it on
    // the hidden data element before submitting the form
    if (this.uploadElement.files && this.uploadElement.files[0]) {
      event.preventDefault();
      this.croppie.result({ type: 'base64', format: 'jpeg' }).then(this.setBase64Data.bind(this));
    }
  }

  setBase64Data(result) {
    // Store the base64 data and continue with form submission
    this.dataElement.value = result;
    this.formElement.submit();
  }
}

var initCropperControllers = (function() {
  var croppers = document.querySelectorAll('[data-toggle="cropper"]');

  if (croppers.length) {
    croppers.forEach(function(cropperElement) {
      new CropperController(cropperElement);
    });
  }
});

document.addEventListener('turbo:load', initCropperControllers);
