var OFFSET_TOP = 50;
$(document).ready(
  function() {
    $(window).scroll(
      function() {
          //console.log(".navbar.offset().top = " + $(".navbar").offset().top)
          $(".navbar").length && $(".navbar").offset().top > OFFSET_TOP
              ? $(".scrolling-navbar").addClass("top-nav-collapse")
              : $(".scrolling-navbar").removeClass("top-nav-collapse")
      }
    )
  }
)
