import { Controller } from "stimulus"

export default class extends Controller {
  static classes = [ "hidden", "shown" ]
  static targets = [ "content" ]

  toggled() {
    this.contentTarget.classList.toggle(this.hiddenClass)
    this.contentTarget.classList.toggle(this.shownClass)
  }
}
