import { Controller } from 'stimulus'
import Dropzone from 'dropzone';
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";


export default class extends Controller {
  static targets = ["messages", "refresh"];

  connect() {
    this.dropZone = createDropZone(this);
    this.dropZone.on("complete", this.uploadFinished.bind(this));

    // Prevent dropzone from also initialising it automatically
    Dropzone.autoDiscover = false;
  }

  uploadFinished(file) {
    console.log("Finished uploading file");
    var import_result = JSON.parse(file.xhr.response);
    console.log(import_result);

    if (import_result['status'] == 'success') {
      var count = import_result['count'];
      this.messagesTarget.textContent = "Successfully loaded data for " + count + " people.";
      this.refreshTarget.classList.remove('d-none');
    } else {
      var error = import_result['error'];
      this.messagesTarget.textContent = "Failed to load data. The problem reported was: " + error;
    }
  }
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks
  });
}