//
// Scrollbar
//

'use strict';

import 'jquery.scrollbar'
import 'jquery-scroll-lock'

var initScrollbar = (function() {
	var $scrollbar = $('.scrollbar-inner');

	if ($scrollbar.length) {
		$scrollbar.scrollbar().scrollLock()
	}

});

document.addEventListener("turbo:load", initScrollbar)
