// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("trix")
require("@rails/actiontext")

Rails.start()
ActiveStorage.start()

import LocalTime from "local-time"
LocalTime.start()

// Stimulus.js
import 'controllers'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Style/theme
import 'jquery.scrollbar'
import 'bootstrap'
import '@fortawesome/fontawesome-pro/js/all.js'
import 'mantis-shrimp/back_to_top'
import 'mantis-shrimp/cropper'
import 'mantis-shrimp/datepicker'
import 'mantis-shrimp/layout'
import 'mantis-shrimp/navbar'
import 'mantis-shrimp/scrollbar'
import '../stylesheets/application'

document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="tooltip"]').popover()
})

// import '@fortawesome/fontawesome-pro/js/all.js'

// Disable GPU acceleration for popovers otherwise we get blurry text.
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = !(window.devicePixelRatio < 1.5 && /Win/.test(navigator.platform));

import 'bootstrap-notify'
import 'web-animations-js'
import 'parallax/parallax-image'
import 'navbar/navbar'
import 'trix/custom_trix'

// Despite the setup in config/webpock/environment.rb this seems to be needed to be able to
// use the $ notation for jquery within <script> tags in the html page itself.
global.$ = require("jquery")

import 'squad_admin/squads'
import 'squad_admin/squad_rosters'
