import { Controller } from 'stimulus'
import $ from 'jquery';

require("select2/dist/css/select2")
require("@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4")

import Select2 from "select2"

export default class extends Controller {
  static targets = [ "select" ]

  connect() {
    console.log(this.selectTarget)

    var options = {
      theme: 'bootstrap4',
    }

    $(this.selectTarget).select2(options)
  }
}
